

























































































































































import { Vue, Component } from "vue-property-decorator";
import { CropVariety } from "@/store/models/cropVariety";
import { CropVarietyStore, CropStore } from "@/store/modules";
import DynamicField from "@/views/Admin/DynamicField.vue";
import UploadMultipleImage from "@/views/Admin/UploadMultipleImage.vue";
import CropVarietyValueList from "@/views/Admin/CropVarietyValueList.vue";
import UploadFile from "@/components/UIComponents/UploadFile.vue";
import { getNestedFormData } from "@/utils/helper.utils";

@Component({
  components: {
    CropVarietyValueList,
    DynamicField,
    UploadMultipleImage,
    UploadFile
  }
})
export default class SCMList extends Vue {
  username: string = "";
  cropId: number = 0;
  cropvariety = {} as CropVariety;
  selectedIndex = -1;
  dialogVisible: boolean = false;
  index: number = 0;

  private get cropvarietyforadmin() {
    return CropStore.CropNameList;
  }

  async setcropvarietyvalue() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      const data = new FormData();
      const cropVarietyData = getNestedFormData(data, this.cropvariety);
      await CropVarietyStore.saveCropVarietyName({ obj: cropVarietyData });
      this.$notify.success("Saved Successfully");
      let cropId = CropVarietyStore.CropValue[0].id;
      if (cropId != undefined) {
        this.$router
          .push({
            name: "CropVarietyValue",
            query: { cropvarietyid: String(cropId), page: String(1) }
          })
          .catch(error => {});
      }
    } else {
      this.$notify.error("Invalid Form");
    }
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }

  private created() {
    CropStore.getAllCropName();
  }
}
