var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cards dynamicFields"},[_c('el-form',{attrs:{"label-position":"top"}},[_c('el-row',[_c('el-form-item',{class:{
          'is-error': _vm.vErrors.has(_vm.$t('crop')),
          'is-required': _vm.checkIfRequired(_vm.$t('crop'))
        },attrs:{"label":_vm.$t('crop')}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"select-default",attrs:{"filterable":"","placeholder":"Select","name":_vm.$t('crop')},model:{value:(_vm.cropvariety.croptypeid),callback:function ($$v) {_vm.$set(_vm.cropvariety, "croptypeid", $$v)},expression:"cropvariety.croptypeid"}},_vm._l((_vm.cropvarietyforadmin),function(text,index){return _c('el-option',{key:index,staticClass:"select-default",attrs:{"label":text.croptypename,"value":text.id}})}),1),(_vm.vErrors.has(_vm.$t('crop')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("crop")))+" ")]):_vm._e()],1)],1),_c('el-row',{staticClass:"formGaps",attrs:{"gutter":12}},[_c('el-col',{staticClass:"mr-5",attrs:{"xs":12,"sm":11}},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has('Variety Name'),
            'is-required': _vm.checkIfRequired('Variety Name')
          },attrs:{"label":'Variety Name'}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"Variety Name","placeholder":"Variety Name"},model:{value:(_vm.cropvariety.name),callback:function ($$v) {_vm.$set(_vm.cropvariety, "name", $$v)},expression:"cropvariety.name"}}),(_vm.vErrors.has('Variety Name'))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first("Variety Name"))+" ")]):_vm._e()],1)],1),_c('el-col',{staticClass:"mr-5",attrs:{"xs":12,"sm":11}},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has('Variety Nepali Name'),
            'is-required': _vm.checkIfRequired('Variety Nepali Name')
          },attrs:{"label":'Variety Nepali Name'}},[_c('el-input',{attrs:{"name":"Variety Nepali Name","placeholder":"Variety Nepali Name"},model:{value:(_vm.cropvariety.namenep),callback:function ($$v) {_vm.$set(_vm.cropvariety, "namenep", $$v)},expression:"cropvariety.namenep"}}),(_vm.vErrors.has('Variety Nepali Name'))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first("Variety Nepali Name"))+" ")]):_vm._e()],1)],1)],1),_c('el-row',{staticClass:"formGaps",attrs:{"gutter":12}},[_c('el-col',{staticClass:"mr-5",attrs:{"xs":12,"sm":11}},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has('DNA Fingerprint'),
            'is-required': _vm.checkIfRequired('DNA Fingerprint')
          },attrs:{"label":'DNA Fingerprint'}},[_c('upload-file',{directives:[{name:"validate",rawName:"v-validate",value:('fileSize:5120'),expression:"'fileSize:5120'"}],attrs:{"name":"DNA Fingerprint","previewImage":true,"list-type":"picture","accept":"image/jpeg,image/png,application/pdf","tip":"File must be image or pdf of size less than 5MB."},model:{value:(_vm.cropvariety.dna_fingerprint),callback:function ($$v) {_vm.$set(_vm.cropvariety, "dna_fingerprint", $$v)},expression:"cropvariety.dna_fingerprint"}}),(_vm.vErrors.has('DNA Fingerprint'))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first("DNA Fingerprint"))+" ")]):_vm._e()],1)],1),_c('el-col',{staticClass:"mr-5",attrs:{"xs":12,"sm":11}},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has('Non GMO Declaration'),
            'is-required': _vm.checkIfRequired('Non GMO Declaration')
          },attrs:{"label":'Non GMO Declaration'}},[_c('upload-file',{directives:[{name:"validate",rawName:"v-validate",value:('fileSize:5120'),expression:"'fileSize:5120'"}],attrs:{"name":"Non GMO Declaration","previewImage":true,"list-type":"picture","accept":"image/jpeg,image/png,application/pdf","tip":"File must be image or pdf of size less than 5MB."},model:{value:(_vm.cropvariety.non_GMO),callback:function ($$v) {_vm.$set(_vm.cropvariety, "non_GMO", $$v)},expression:"cropvariety.non_GMO"}}),(_vm.vErrors.has('Non GMO Declaration'))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first("Non GMO Declaration"))+" ")]):_vm._e()],1)],1)],1),_c('div',{staticClass:"gutter-sd text-right"},[_c('router-link',{attrs:{"to":{ name: 'CropVarietyValueList' }}},[_c('el-button',{staticClass:"margin",attrs:{"plain":""}},[_vm._v("Cancel")])],1),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.setcropvarietyvalue()}}},[_vm._v("Save")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }